
import { roles } from "@/consts/roles";
import { MarketRequest } from "@/models/request/MarketRequest";
import { MarketRequestStatus } from "@/models/request/MarketRequestStatus";
import { marketRequestModule } from "@/store/modules/marketRequest";
import { MarketRequestGetters } from "@/store/modules/marketRequest/getters";
import moment from "moment-timezone";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    computed: {
        ...marketRequestModule.mapGetters({
            selectedMarketRequest: MarketRequestGetters.SelectedMarketRequest
        })
    }
})
export default class RequestStatusHistory extends Vue {
    private readonly selectedMarketRequest!: MarketRequest | null;

    get statusHistory(): MarketRequestStatus[] {
        return this.selectedMarketRequest?.statusHistory ?? [];
    }

    isMarketComment(status: MarketRequestStatus): boolean {
        return status.user.role == roles.MSU || status.user.role == roles.MU;
    }

    getFormattedDate(value: Date): string {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const userDate = moment.utc(value).tz(userTimeZone);

        // Format the time as dd.mm.yyyy | HH:MM:ss
        return userDate.format("DD.MM.YYYY | HH:mm:ss");
    }

    scrollToStatus(statusId: number): void {
        const statusElement = this.$refs[`status-${statusId}`] as HTMLElement[];
        if (statusElement && statusElement[0]) {
            statusElement[0].scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }
    }
}
