
import Component from "vue-class-component";
import Vue from "vue";
import RequestForm from "@/components/RequestForm";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import {
    disableLeaveConfirmation,
    enableLeaveConfirmation,
    leaveConfirmation
} from "@/services/leaveConfirmation";
import { Routes } from "@/router/routes";
import { RequestActions } from "@/store/modules/request/actions";

@Component({
    components: { RequestForm },
    computed: {
        ...requestModule.mapGetters({
            isEdited: RequestGetters.IsEdited
        })
    },
    methods: {
        ...requestModule.mapActions({
            loadRequestById: RequestActions.LoadRequestById
        })
    }
})
export default class EditDraft extends Vue {
    private readonly loadRequestById!: (requestId: number) => Promise<void>;

    private readonly isEdited!: boolean;

    created(): void {
        if (!this.requestId) {
            this.$router.push(Routes.Drafts);
        } else {
            this.loadRequestById(this.requestId);
            enableLeaveConfirmation(() => this.isEdited);
        }
    }

    beforeDestroy(): void {
        disableLeaveConfirmation();
    }

    beforeRouteLeave(
        _to: never,
        _from: never,
        next: () => void
    ): boolean | void {
        leaveConfirmation(this.isEdited, next);
    }

    get requestId(): number | null {
        const id = this.$router.currentRoute.params.id;
        const parsedId = Number(id);
        return isNaN(parsedId) ? null : parsedId;
    }
}
