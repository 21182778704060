
import Vue from "vue";
import Component from "vue-class-component";
import { requestModule } from "@/store/modules/request";
import { RequestGetters } from "@/store/modules/request/getters";
import { Request } from "@/models/request/Request";
import { ValidationResult } from "devextreme/ui/validation_group";
import { RequestActions } from "@/store/modules/request/actions";
import DxValidationGroup from "devextreme-vue/validation-group";
import notify from "devextreme/ui/notify";
import FixedPanel from "@/components/FixedPanel";
import BaseButton from "@/components/BaseButton";
import RequestSelectFieldsStep from "../RequestSelectFieldsStep";
import RequestSettingsForm from "../RequestBasicsStep";
import DynamicTitle from "@/components/DynamicTitle";
import validationService from "@/services/validationService";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import { Routes } from "@/router/routes";
import { RequestMutations } from "@/store/modules/request/mutations";
import {
    formatValidationErrors,
    isValidationError
} from "@/services/responseErrorUtils";

enum SetupStep {
    Basics = 1,
    SelectFields
}

@Component({
    components: {
        DxValidationGroup,
        BaseButton,
        RequestSettingsForm,
        RequestSelectFieldsStep,
        FixedPanel,
        DynamicTitle
    },
    computed: {
        ...requestModule.mapGetters({
            request: RequestGetters.MarketRequest,
            isLoading: RequestGetters.IsLoading,
            errors: RequestGetters.Errors
        })
    },
    methods: {
        ...requestModule.mapActions({
            saveRequest: RequestActions.SaveRequest,
            sendRequest: RequestActions.SendRequest,
            loadRequestById: RequestActions.LoadRequestById
        }),
        ...requestModule.mapMutations({
            setEditingState: RequestMutations.SetEditingState
        })
    }
})
export default class RequestForm extends Vue {
    protected readonly saveRequest!: () => Promise<void>;
    protected readonly sendRequest!: () => Promise<void>;
    protected readonly loadRequestById!: (requestId: number) => Promise<void>;
    protected readonly setEditingState!: (isEdited: boolean) => void;

    protected readonly request!: Request;

    protected readonly isLoading!: boolean;
    protected readonly errors!: string[] | null;

    protected steps = SetupStep;
    protected currentStep = SetupStep.Basics;
    protected titles: { [K: number]: string } = {
        [SetupStep.Basics]: "Basics and Briefing",
        [SetupStep.SelectFields]: "Pre-select Terms and additional information"
    };

    private validateFirstStep = false;

    $refs!: Vue["$refs"] & {
        validationGroup: DxValidationGroup;
    };

    get nextButtonIcon(): string {
        switch (this.currentStep) {
            case SetupStep.Basics:
                return "vehicle";
            case SetupStep.SelectFields:
                return "send";
            default:
                return "";
        }
    }

    get nextButtonText(): string {
        switch (this.currentStep) {
            case SetupStep.Basics:
                return "Select Models";
            case SetupStep.SelectFields:
                return "Send";
            default:
                return "";
        }
    }

    get nextButtonDisabled(): boolean {
        if (this.isLoading) return true;

        if (
            this.currentStep === SetupStep.Basics &&
            (this.request.requestTypeId === null ||
                !this.request.supplyScenarios.length)
        ) {
            return true;
        }
        return false;
    }

    get currentStepTitle(): string {
        switch (this.currentStep) {
            case SetupStep.Basics:
                return "Basics and Briefing";
            case SetupStep.SelectFields:
                return "Pre-select Terms and additional information";
            default:
                return "";
        }
    }

    get titleElements(): TitleElement[] {
        const result: TitleElement[] = [];
        for (let i = 1; i <= this.currentStep; i++) {
            result.push({
                name: this.titles[i],
                selected: i === this.currentStep,
                id: i
            });
        }
        return result;
    }

    nextButtonClick(): void {
        // Skip first step -> validate in last step
        if (this.currentStep === SetupStep.Basics && !this.validateFirstStep) {
            this.currentStep++;
            return;
        }

        const result: ValidationResult | undefined =
            this.$refs.validationGroup?.instance?.validate();
        if (result?.isValid) {
            if (this.currentStep !== SetupStep.SelectFields) {
                this.currentStep++;
            } else if (!validationService.validateRequest(this.request)) {
                this.currentStep = SetupStep.Basics;
                this.validateFirstStep = true;
                this.$nextTick(() => {
                    this.$refs.validationGroup?.instance?.validate();
                });
            } else {
                this.sendRequest().then(() => {
                    if (this.errors?.length) {
                        this.showErrors(this.errors);
                        return;
                    }

                    this.setEditingState(false); // Set editing state to false to avoid confirmation leave
                    this.$router.push(Routes.RequestsInProgress);
                });
            }
        } else if (result) {
            let message = "Validation error!";
            const customRules = result.brokenRules?.filter(
                (r) => r.type === "custom"
            );
            if (customRules?.length) {
                customRules.forEach((c) => {
                    message += ` ${c.message}`;
                });
            }
            notify(message, "error", 3000);
        }
    }

    previousButtonClick(): void {
        if (this.currentStep !== SetupStep.Basics) {
            this.currentStep--;
        }
    }

    saveClick(): void {
        this.setEditingState(false);
        this.saveRequest().then(() => {
            if (this.errors?.length) {
                this.showErrors(this.errors);
                return;
            }

            if (this.request.requestId) {
                this.loadRequestById(this.request.requestId);
            }
        });
    }

    showErrors(errors: string[]): void {
        if (isValidationError(errors[0])) {
            notify(formatValidationErrors(errors), "error", 3000);
        } else {
            notify(errors.join(", "), "error", 3000);
        }
    }
}
