
import Component from "vue-class-component";
import Vue from "vue";
import RequestForm from "@/components/RequestForm";
import { requestModule } from "@/store/modules/request";
import { RequestMutations } from "@/store/modules/request/mutations";
import { RequestGetters } from "@/store/modules/request/getters";
import {
    disableLeaveConfirmation,
    enableLeaveConfirmation,
    leaveConfirmation
} from "@/services/leaveConfirmation";

@Component({
    components: { RequestForm },
    computed: {
        ...requestModule.mapGetters({
            isEdited: RequestGetters.IsEdited
        })
    },
    methods: {
        ...requestModule.mapMutations({
            clearRequest: RequestMutations.ClearRequest
        })
    }
})
export default class CreateMarketRequest extends Vue {
    private readonly clearRequest!: () => void;

    private readonly isEdited!: boolean;

    created(): void {
        this.clearRequest();
        enableLeaveConfirmation(() => this.isEdited);
    }

    beforeDestroy(): void {
        disableLeaveConfirmation();
    }

    beforeRouteLeave(
        _to: never,
        _from: never,
        next: () => void
    ): boolean | void {
        leaveConfirmation(this.isEdited, next);
    }
}
