
import Vue from "vue";
import Component from "vue-class-component";
import RequestList from "@/components/RequestList";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { requestModule } from "@/store/modules/request";
import { RequestActions } from "@/store/modules/request/actions";
import { RequestGetters } from "@/store/modules/request/getters";
import { RequestState } from "@/models/request/Request";
import { Routes } from "@/router/routes";
import { isUserInRoles } from "@/services/userUtils";
import queryService from "@/services/queryService";
import { Route } from "vue-router";
import { Watch } from "vue-property-decorator";
import { RequestListColumnName } from "@/models/request/list/RequestListColumnName";
import {
    RequestListOptions,
    getDefaultRequestListOptions
} from "@/models/request/list/RequestListOptions";
import {
    standardRequestListFilterValues,
    standardRequestListPaginationValues,
    standardRequestListSortingValues
} from "@/consts/requestListOptionsValues";
import CustomStore from "devextreme/data/custom_store";

@Component({
    components: {
        RequestList,
        ConfirmationDialog
    },
    computed: {
        ...requestModule.mapGetters({
            isLoading: RequestGetters.IsLoading
        })
    },
    methods: {
        ...requestModule.mapActions({
            deleteDraft: RequestActions.DeleteDraft,
            createDataSource: RequestActions.CreateRequestDataSource
        })
    }
})
export default class Drafts extends Vue {
    private readonly deleteDraft!: (requestId: number) => Promise<void>;
    private readonly createDataSource!: (payload: {
        state: RequestState;
    }) => Promise<CustomStore>;

    protected readonly isLoading!: boolean;

    protected deleteDialog = false;
    protected selectedIndex: number | null = null;

    protected requestDataSource: CustomStore | null = null;

    public requestListOptions: RequestListOptions =
        getDefaultRequestListOptions();

    get requestListColumns(): Array<RequestListColumnName | null> {
        return [
            "requestType",
            "customer",
            "marketDeadline",
            this.isUserHQ ? "customerDeadline" : null
        ];
    }

    $refs!: {
        requestList: RequestList;
    };

    async created(): Promise<void> {
        this.setRequestListOptionsFromQuery(this.$route.query);
        this.requestDataSource = await this.createDataSource({
            state: RequestState.New
        });
    }

    viewDraft(requestId: number): void {
        this.$router.push(Routes.Draft + requestId);
    }

    showDialog(requestId: number): void {
        this.selectedIndex = requestId;
        this.deleteDialog = true;
    }

    async deleteRequest(): Promise<void> {
        if (this.selectedIndex) {
            await this.deleteDraft(this.selectedIndex);
            this.selectedIndex = null;

            await this.$refs.requestList.refresh();
        }
    }

    get isUserHQ(): boolean {
        return isUserInRoles(["Admin", "SU", "HV"]);
    }

    setRequestListOptionsFromQuery(query: Route["query"]): void {
        this.requestListOptions.filter = queryService.getValues(
            query,
            standardRequestListFilterValues
        );
        this.requestListOptions.pagination = queryService.getValues(
            query,
            standardRequestListPaginationValues
        );
        this.requestListOptions.sorting = queryService.getValues(
            query,
            standardRequestListSortingValues
        );
    }

    @Watch("requestListOptions", { deep: true })
    updateRequestListOptions(): void {
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.filter
        });
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.pagination
        });
        queryService.setValues(this.$route.query, {
            ...this.requestListOptions.sorting
        });
    }
}
